// Bootstrap functions
//@import "~bootstrap/scss/functions";
//@import "~bootstrap/scss/variables";
//@import "~bootstrap/scss/mixins";
//@import "~bootstrap/scss/utilities";

// LazyLoad

img:not([src]):not([srcset]) {
	visibility: hidden;
}
